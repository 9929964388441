export function getUserDeviceInfo() {
    const deviceId = Math.random().toString(36).substring(2, 10);
    const deviceModel = navigator.appVersion;
    const lang = navigator.language;
    const osVersion = navigator.platform;

    const formData = new FormData();
    formData.append("deviceId", deviceId);
    formData.append("deviceModel", deviceModel);
    formData.append("lang", lang);
    formData.append("osVersion", osVersion);

    const jsonData: { [key: string]: any } = {};

    formData.forEach((value: any, key: any) => {
      jsonData[key] = value;
    });

    return JSON.stringify(jsonData);
  }