
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";

import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { ScrollComponent } from "@/presentation/assets/ts/components/_ScrollComponent";
import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
import Swal from "sweetalert2";
import { Timer } from "@/presentation/helper/Timer";
import { environment } from "../../../../../../environment";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";
import { ChatSectionResponseDto } from "@/domain/pollster/model/chatSection/ChatSectionResponseDto";
import { useI18n } from "vue-i18n";
import { getUserDeviceInfo } from "@/presentation/helper/DeviceInfo";
import SocketService from "@/infrastructure/web-socket/SocketService";
import { MessageFlow } from "@/presentation/helper/MessageFlow";
import MediaRecorderService from "@/presentation/helper/MediaRecorder";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";
import { ChatLogResponseAttributes } from "@/domain/pollster/model/chatLog/ChatLogResponseAttributes";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
    MessageIn,
    MessageOut,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    let token: undefined | string = undefined;
    const surveyId = route.params["id"];
    const isSurveySessionActive = ref(false);

    const socketService = new SocketService();
    const mediaRecorderService = new MediaRecorderService();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const fileController = store.state.ControllersModule.fileController;
    const swalNotification = new SwalNotification();

    const videoElement = ref<null | HTMLVideoElement>(null);
    const messagesRef = ref<null | HTMLElement>(null);
    const isGifPlaying = ref(false);
    const isRecording = ref(false);
    const isLoadingResponse = ref(false);
    const isError = ref(false);
    const isAiActive = ref(false);
    const audioList: HTMLAudioElement[] = [];
    const AItext = ref("");
    const AiSpeakSecond = ref(0);
    let video: any;

    const timer = new Timer();

    let completionPercentage = ref(0);
    const duration = ref("00:00");
    const sessionTime = localStorage.getItem(LOCAL_STORAGE.TIMER);

    const messages = ref<Array<ChatLogResponseAttributes>>([
      {
        owner: "AI",
        text: t("modals.pollster.firstAiMessage"),
        createdAt: new Date().toLocaleTimeString("tr-TR", {
          hour: "2-digit",
          minute: "2-digit",
        }),
      },
    ]);

    onBeforeUnmount(() => {
      timer.stopTimer();
    });

    watch(AItext, (newValue) => {
      if (messages.value.length > 0) {
        messages.value[messages.value.length - 1].text = newValue;
      }
    });

    const startChat = () => {
      const urlPath = token ? `?token=${token}` : `?survey=${surveyId}`;
      //    const urlPath = token ? `?token=${token}` +  `?survey=${surveyId}` : `?survey=${surveyId}`;

      socketService.connect(urlPath, () => {
        if (socketService.isConnected()) {
          startUserCamera();
          isAiActive.value = true;
          isSurveySessionActive.value = true;
          isLoadingResponse.value = true;
          messages.value = [];
          showStartChatAlert();

          if (sessionTime !== null) {
            const [hours, minutes] = sessionTime.split(":");
            const totalMinutes =
              parseInt(hours, 10) * 60 + parseInt(minutes, 10);
            timer.startTimer((formattedDuration: string) => {
              duration.value = formattedDuration;
            }, totalMinutes);
          } else {
            timer.startTimer((formattedDuration: string) => {
              duration.value = formattedDuration;
            });
          }

          socketService.addEventListener("message", handleMessage);
        }
      });
    };

    const handleMessage = (message: any) => {
      // message {"data":null,"error":{"status":403,"name":"ForbiddenError","message":"Forbidden","details":{}}}

      if (JSON.parse(message).data == null) {
        timer.stopTimer();
        isLoadingResponse.value = false;
        isError.value = true;
        stopUserCamera();

        Swal.fire({
          text: "Anket başlatılamadı, geçersiz anket ya da token limitiniz dolmuş olabilir. lütfen Yönetici ile iletişime geçin.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
          heightAuto: false,
        }).then(() => router.push({ name: "doPoll" }));
      } else {
        let res: ChatSectionResponseDto = JSON.parse(message);
        processResponse(res);
      }
    };

    const processResponse = (res: ChatSectionResponseDto) => {
      const userDeviceData = getUserDeviceInfo();
      socketService.sendMessage(userDeviceData);

      if (res.data.attributes.productSessionMessages.data.length > 0) {
        const newMessages = res.data.attributes.productSessionMessages.data.map(
          (message: any, index: number) => {
            const isLastMessage =
              index ===
              res.data.attributes.productSessionMessages.data.length - 1;
            return {
              owner: message.attributes.owner,
              text:
                isLastMessage && message.attributes.owner == "AI"
                  ? ""
                  : message.attributes.text,
              createdAt: new Date(
                message.attributes.createdAt
              ).toLocaleTimeString("tr-TR", {
                hour: "2-digit",
                minute: "2-digit",
              }),
            };
          }
        );

        messages.value = newMessages;
        ScrollComponent.updateAll();

        if (res.data.attributes.voice.data) {
          let url =
            environment.baseUploadUrl +
            res.data.attributes.voice.data.attributes.url;
          startAIVoice(url).then(() => {
            const lastMessage =
              res.data.attributes.productSessionMessages.data[
                res.data.attributes.productSessionMessages.data.length - 1
              ];
            if (lastMessage.attributes.owner === "AI") {
              MessageFlow(
                lastMessage.attributes.text,
                Number(AiSpeakSecond.value),
                messages.value,
                messages.value.length - 1
              );
              ScrollComponent.updateAll();
            }
          });
        }
        completionPercentage.value = res.data.attributes.completionPercentage;
      }

      if (res.data.attributes.endDate) {
        Swal.fire({
          title: "Tebrikler!",
          text: "Görüşmeyi başarıyla tamamladınız...",
          icon: "success",
          heightAuto: false,
        }).then(() => {
          timer.stopTimer();
          localStorage.clear();
          window.location.reload();
        });
      }
    };

    const showStartChatAlert = () => {
      Swal.fire({
        text: "Görüşme başladı.",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
        heightAuto: false,
      }).then(() => router.push({ name: "doPoll" }));
    };

    const startRecording = async () => {
      video = document.getElementById("userCamera");
      if (video.srcObject) {
        mediaRecorderService.startVideoRecording(video);
      }
      isRecording.value = true;
      stopAIVoice();

      mediaRecorderService.startAudioRecording();
    };

    const startUserCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoElement.value) {
          videoElement.value.srcObject = stream;
          videoElement.value.style.display = "block";
        }
      } catch (error) {
        Swal.fire({
          text: "Kamera erişimi reddedildi veya kullanılamıyor.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
          heightAuto: false,
        }).then(function () {
          router.push({ name: "doPoll" });
        });
      }
    };

    const stopUserCamera = async () => {
      if (videoElement.value) {
        videoElement.value.style.display = "none";
      }
    };

    const stopRecording = () => {
      isRecording.value = false;
      if (
        mediaRecorderService.videoRecorder &&
        mediaRecorderService.videoRecorder.state !== "inactive" &&
        mediaRecorderService.audioRecorder &&
        mediaRecorderService.audioRecorder.state !== "inactive"
      ) {
        mediaRecorderService.stopVideoRecording();
        mediaRecorderService.stopAudioRecording();
        if (mediaRecorderService.audioRecorder)
          mediaRecorderService.audioRecorder.onstop = () => {
            const audioBlob = new Blob(mediaRecorderService.audioChunks, {
              type: "audio/mp3",
            });
            const videoBlob = new Blob(mediaRecorderService.videoChunks, {
              type: "video/mp4",
            });
            sendBlobToAPI(audioBlob, videoBlob);
            mediaRecorderService.audioChunks = [];
            mediaRecorderService.videoChunks = [];
          };
      }
    };

    const sendBlobToAPI = (audioBlob: Blob, videoBlob: Blob) => {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioElement = new Audio(audioUrl);

      audioElement.addEventListener("loadedmetadata", () => {
        if (
          audioElement.duration === Infinity ||
          isNaN(Number(audioElement.duration))
        ) {
          audioElement.currentTime = 1e101;
          audioElement.addEventListener("timeupdate", getDuration);
        }
      });

      function getDuration(event) {
        event.target.currentTime = 0;
        event.target.removeEventListener("timeupdate", getDuration);
        console.log(event.target.duration);
        if (event.target.duration < 1) {
          isLoadingResponse.value = false;
          return Swal.fire({
            text: "Lütfen daha uzun ses kaydediniz.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
            heightAuto: false,
          }).then(function () {
            router.push({ name: "doPoll" });
          });
        } else {
          const soundFile = new File([audioBlob], "userAudio.mp3", {
            type: "audio/mp3",
          });

          const soundUploadModel = new FileUploadModel(soundFile);

          isLoadingResponse.value = true;

          fileController
            .fileUpload(soundUploadModel)
            .then((response) => {
              if (response.isSuccess) {
                let res = response.getValue();
                let soundId = res.id;

                const data = {
                  voice: soundId,
                };

                socketService.sendMessage(JSON.stringify(data));
                sendVideoToAPI(soundId, videoBlob);
              } else {
                return Swal.fire({
                  text: "Lütfen tekrar deneyiniz.",
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: "Tamam",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                  heightAuto: false,
                }).then(function () {
                  router.push({ name: "doPoll" });
                });
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      }
    };

    const sendVideoToAPI = (soundId: string, videoBlob: Blob) => {
      const videoFile = new File([videoBlob], "userVideo.mp4", {
        type: "video/mp4",
      });

      const videoUploadModel = new FileUploadModel(videoFile);

      console.log("videoUploadModel", videoUploadModel);
t
      fileController
        .fileUpload(videoUploadModel)
        .then((response) => {
          if (response.isSuccess) {
            let res = response.getValue();
            let videoId = res.id;

            const data = {
              voice: soundId,
              video: videoId,
            };

            socketService.sendMessage(JSON.stringify(data));
          } else {
            return Swal.fire({
              text: "Video gönderilemedi, lütfen tekrar deneyiniz.",
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: "Tamam",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
              heightAuto: false,
            }).then(function () {
              router.push({ name: "doPoll" });
            });
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const stopChat = async () => {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Görüşmeyi sonlandırmak ister misiniz?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        cancelButtonText: "İptal",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Görüşme sonlandı.",
            text: "Görüşmeyi başarıyla sonlandırdınız.",
            icon: "success",
            heightAuto: false,
          }).then(() => {
            const data = {
              closeSession: true,
            };

            socketService.sendMessage(JSON.stringify(data));
            isAiActive.value = false;
            timer.stopTimer();
            localStorage.clear();
            window.location.reload();
          });
        }

        router.push({ name: "doPoll" });
      });
    };

    const startAIVoice = (url: string) => {
      return new Promise((resolve, reject) => {
        isLoadingResponse.value = false;
        isGifPlaying.value = true;
        const aiVoice = new Audio(url);

        aiVoice.addEventListener("loadedmetadata", () => {
          AiSpeakSecond.value = aiVoice.duration;
          resolve(aiVoice.duration);
        });

        aiVoice.play();
        audioList.push(aiVoice);

        aiVoice.onended = () => {
          isGifPlaying.value = false;
        };

        aiVoice.onerror = (error) => {
          reject(error);
        };
      });
    };

    const stopAIVoice = () => {
      audioList.forEach((audio) => {
        if (!audio.paused) {
          audio.pause();
          isGifPlaying.value = false;
          audio.currentTime = 0;
        }
      });
    };

    onMounted(() => {
      token = route.query.token?.toString();
      ScrollComponent.bootstrap();

      if (!messagesRef.value) {
        return;
      }
    });

    return {
      isAiActive,
      isError,
      isLoadingResponse,
      startChat,
      stopChat,
      startRecording,
      stopRecording,
      videoElement,
      isGifPlaying,
      isRecording,
      AItext,
      completionPercentage,
      duration,
      messages,
      messagesRef,
      isSurveySessionActive,
    };
  },
});
